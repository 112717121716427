<template lang="">
    <div>
        <Ekle />
    </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
    name:"kiraci ekle",
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Kiracı Ekle', route: '/kiracilar/kiraci-ekle' },
        ]);
    },
    components:{
        Ekle: () => import("@/components/kiracilar/Ekle")
    }
}
</script>
<style lang="">
    
</style>